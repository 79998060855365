.container{
  min-height: 100vh;
  position: relative;
  background: #f8fafd;
  .banner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    width: 150%;
    height: 500px;
    left: 50%;
    top: 0;
    overflow: hidden;
    border-radius: 0 0 50% 50%;
    transform: translateX(-50%);
    background: center / cover url(https://static.zhubaofang.cn/website/images/banner/shapes-dark.png) no-repeat;
    .slogan{
      text-align: center;
      color: white;
      .title{
        font-size: 150px;
        letter-spacing: 10px;
        cite{
          font-style: normal;
          background: -webkit-linear-gradient(30deg, rgba(0, 222, 169, 0.9), rgba(117, 176, 243, 0.9));
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      .subtitle{
        padding-top: 12px;
        font-size: 22px;
        letter-spacing: 5px;
      }
    }
  }
  .products{
    padding: calc(500px - 86px) 0 72px 0;
    margin: 72px 80px 0 112px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    &,ul,ul > li {
      box-sizing: content-box;
    }
    ul{
      display: flex;
      flex-wrap: wrap;
      width: 984px;
      li{
        width: 214px;
        height: 180px;
        padding-right: 32px;
        padding-bottom: 32px;
        border-radius: 8px;
        cursor: pointer;
        position: relative;
        a{
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          height: 100%;
          text-align: center;
          background-color: #fff;
          border-radius: 8px;
          transition: transform .3s;
          line-height: 24px;
          color: black;
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
          &:hover{
            transform: scale(1.1);
          }
          i,svg{
            width: 40px;
            height: 40px;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
  @media (min-width: 1440px) {
    .products{
      ul{
        width: 1056px;
        li{
          width: 232px;
          height: 196px;
        }
      }
    }
  }
  @media (max-width: 1176px) {
    .products{
      ul{
        width: 738px;
      }
    }
  }
  @media (max-width: 930px) {
    .banner{
      height: 450px;
    }
    .products{
      padding: calc(450px - 86px) 0 72px 0;
      ul{
        width: 492px;
      }
    }
  }
  @media (max-width: 760px) {
    .products{
      margin-left: 0;
      margin-right: -32px;
      ul{
        width: 464px;
        li{
          width: 200px;
          height: 168px;
        }
      }
    }
  }
  @media (max-width: 600px) {
    .products{
      margin-left: auto;
      margin-right: auto;
      ul{
        min-width: 0;
        width: 390px;
        margin-right: -24px;
        li{
          width: 171px;
          height: 143px;
          padding-right: 24px;
          padding-bottom: 24px;
        }
      }
    }
  }
  @media (max-width: 414px) {
    .banner{
      height: 400px;
      .slogan{
        .title{
          font-size: 110px;
        }
        .subtitle{
          font-size: 16px;
        }
      }
    }
    .products{
      padding: calc(400px - 100px) 24px 58px 24px;
      ul{
        margin-right: 0;
        width: 100%;
        li{
          width: 50%;
          box-sizing: border-box;
          &:nth-child(odd){
            padding-right: 12px;
          }
          &:nth-child(2n){
            padding-right: 0;
            padding-left: 12px
          }
        }
      }
    }
  }
}